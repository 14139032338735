import * as React from 'react';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import AspectRatio from '@mui/joy/AspectRatio';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import useDimensions from "react-cool-dimensions";

const brands = {
    azazie: require('../images/azazie.jpeg'),
    davidsbridal: require('../images/davidsbridal.jpg'),
    lulus: require('../images/lulus.png')
};

export default function Product(props) {
    const [imgHeight, setImgHeight] = React.useState(props.colWidth / props.product.image_ratio);
    const { observe, unobserve, width, height, entry } = useDimensions({
        onResize: ({ observe, unobserve, width, height, entry }) => {
            // Triggered whenever the size of the target is changed...
            if (width) {
                setImgHeight(width / props.product.image_ratio);
            }
        },
    });

    const imageExists = (image_url) => {

        var http = new XMLHttpRequest();
    
        http.open('HEAD', image_url, false);
        http.send();
    
        return http.status != 404;
    
    }

    const maxCols = 6;
    const minCols = 2;
    const minColWidth = 300;

    let columnWidth = window.innerWidth / maxCols;
    // decide how big to make cols
    const numCols = Math.floor(window.innerWidth / minColWidth);
    if (numCols < minCols) {
        columnWidth = window.innerWidth / 2;
    } else {
        columnWidth = window.innerWidth / numCols;
    }

    columnWidth = columnWidth - 30;

    return (
        <div style={{ flexDirection: "column", padding: 10, display: "flex", alignItems: "center" }} ref={observe}>
            <div style={{ position: "relative" }}>
                <a href={props.product.page_link} target="_blank">
                    <img className="shadow-card" src={props.product.image} style={{ width: columnWidth, maxWidth: columnWidth, borderRadius: 20, height: columnWidth / (props.product.image_ratio ?? 0.67) }} />
                </a>
                <img src={brands[props.product.vendor]} style={{ height: 40, borderRadius: 10, position: "absolute", bottom: 20, left: 20, display: "none" }} />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                padding: 5,
                width: "100%"
            }}>
                <div style={{ fontWeight: "bold" }}>
                    <span style={{ color: "black" }}>{props.product.name}</span>
                </div>
                {/* <span style={{ color: "rgba(0,0,0,0.7)", fontSize: 12 }}>{props.product.description}</span> */}
                <div style={{ fontWeight: "normal", marginTop: 5, color: "rgba(0,0,0,0.6)" }}>
                <img src={brands[props.product.vendor]} style={{ height: 40, borderRadius: 10, marginTop: 5 }} />
                </div>
            </div>
        </div>
    );
}

const styles = {
    card: {
        width: "100%",

    }
}