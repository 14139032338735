import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import {
    Button,
    CompositeZIndex,
    FixedZIndex,
    Flex,
    IconButton,
    Heading,
    Layer,
    Modal,
    RadioGroup,
    Divider,
    Text,
    Box,
    OverlayPanel,
    SideNavigation,
    Icon
} from 'gestalt';
import { HexColorPicker } from "react-colorful";
import { Link, Redirect, Route, useHistory, useParams } from "react-router-dom";

import '@fontsource/public-sans';
import 'gestalt/dist/gestalt.css';

import { Masonry } from 'gestalt';
import Product from '../components/Product';

import mainLogo from '../images/shoptheswatch.svg';
import downtownStore from '../images/downtown-store.jpeg';
import colorfulDresses from '../images/colorful-dresses.jpeg';

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const Privacy = (props) => {
    const [showNav, setShowNav] = useState(false);
    const HEADER_ZINDEX = new FixedZIndex(10);

    const sheetZIndex = new CompositeZIndex([HEADER_ZINDEX]);

    const navigation = useHistory();

    return (
        <>
            <div className="page-container">
                <div className="page-header" style={{ borderBottomColor: "rgba(0,0,0,0.1)", borderBottomWidth: 1 }}>
                    <div className="logo">
                        <img src={mainLogo} />
                    </div>
                    <div onClick={() => { setShowNav(!showNav) }} style={{ marginLeft: 20, color: "black", cursor: "pointer" }}>
                        <Icon icon="menu" size="20" />
                    </div>
                </div>
                <div className="scroll-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "white" }}>
                    <div style={{ maxWidth: 700,
                                padding: 20 }}>
                        <Flex
                            direction="column"
                            gap={{
                                row: 0,
                                column: 12,
                                gap: 15
                            }}
                        >
                            <div style={{ color: "black" }}><h1>Privacy Policy</h1>
                                <p>This document describes the types of data ShopTheSwatch collects, what we do with it, and how you can manage and revoke our access to it.</p>
                                <h1>User Information</h1>
                                <p>ShopTheSwatch never displays your E-Mail address. ShopTheSwatch never shares personally identifying information with any third party unless that third party is used to provide services. In which case:</p>
                                <ul>
                                    <li>ShopTheSwatch may transfer payment and or other personally identifying information to third party payment processors for in-app purchased.</li>
                                    <li>ShopTheSwatch may transfer personally identifying information such as your e-mail address or phone number to third party notification services so we can send you alerts regarding your account.</li>
                                </ul>
                                <p>ShopTheSwatch does NOT sell any data to third parties under any circumstance, in aggregate or otherwise. ShopTheSwatch takes no responsibility for any data that passes through third parties.</p>
                                <h1>Data Collection</h1>
                                <p>Through your use of ShopTheSwatch, ShopTheSwatch may collect the following kinds of data points from your usage:</p>
                                <ul>
                                    <li>IP (Internet Protocol) Address: We store the IP of every device and network you access ShopTheSwatch from.</li>
                                    <li>ShopTheSwatch Browsing History: We may collect data regarding your usage of ShopTheSwatch, such as items you view, like and share as well as other general browsing behaviors on ShopTheSwatch. We do NOT collect external browsing data. We only track what you do on ShopTheSwatch to provide a better experience for you and other users on the platform. We don't share browsing data with anyone.</li>
                                    <li>Payment Information: If you choose to make a purchase on ShopTheSwatch, we may collect and transfer (but not store) payment information so that we can complete the purchase transaction. No payment information is stored on ShopTheSwatch servers.</li>
                                    <li>Device IDs: ShopTheSwatch may collect Device Identification information for the purposes of Push Notifications and is not shared with any other users or third parties and is stored on it's internal servers.</li>
                                </ul>
                                <p>ShopTheSwatch does not link any data it collects back to you or otherwise makes no attempt to collect data for the purpose of identifying you. ShopTheSwatch never shares submitted documentation with third parties without explicit prior consent.</p>
                                <h2>Third Party Anonymous Data Collection</h2>
                                <p>Some anonymous usage data may be collected by third party tools ShopTheSwatch uses for it's application development. No personal or specific user data is shared. Data that may be collected by third party tools are things such as number of installations of our mobile app, regions of installs and crash statistics.</p></div>
                        </Flex>
                    </div>
                </div>
            </div>

            {showNav && (
                <Layer zIndex={sheetZIndex}>
                    <OverlayPanel size="sm" onDismiss={() => { setShowNav(false) }}>
                        <SideNavigation accessibilityLabel="Main example">
                            <SideNavigation.TopItem
                                href="/"
                                onClick={({ event }) => window.location.href = "/"}
                                label="Home"
                            />
                            <SideNavigation.TopItem
                                href="/about"
                                onClick={({ event }) => window.location.href = "/about"}
                                label="About"
                            />
                            <SideNavigation.TopItem
                                href="/terms"
                                onClick={({ event }) => window.location.href = "/terms"}
                                label="Terms of Use"
                            />
                            <SideNavigation.TopItem
                                href="/privacy"
                                onClick={({ event }) => window.location.href = "/privacy"}
                                label="Privacy Policy"
                            />
                        </SideNavigation>
                    </OverlayPanel>
                </Layer>
            )}
        </>
    )
}

export default Privacy;