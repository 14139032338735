import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import {
    Button,
    CompositeZIndex,
    FixedZIndex,
    Flex,
    IconButton,
    Heading,
    Layer,
    Modal,
    RadioGroup,
    Divider,
    Text,
    Box,
    OverlayPanel,
    SideNavigation,
    Icon
} from 'gestalt';
import { HexColorPicker } from "react-colorful";
import { Link, Redirect, Route, useHistory, useParams } from "react-router-dom";

import '@fontsource/public-sans';
import 'gestalt/dist/gestalt.css';

import { Masonry } from 'gestalt';
import Product from '../components/Product';

import mainLogo from '../images/shoptheswatch.svg';
import downtownStore from '../images/downtown-store.jpeg';
import colorfulDresses from '../images/colorful-dresses.jpeg';

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const About = (props) => {
    const [showNav, setShowNav] = useState(false);
    const HEADER_ZINDEX = new FixedZIndex(10);

    const sheetZIndex = new CompositeZIndex([HEADER_ZINDEX]);

    const navigation = useHistory();

    return (
        <>
        <div className="page-container">
            <div className="page-header" style={{ borderBottomColor: "rgba(0,0,0,0.1)", borderBottomWidth: 1 }}>
                <div className="logo">
                    <img src={mainLogo} />
                </div>
                <div onClick={() => { setShowNav(!showNav) }} style={{ marginLeft: 20, color: "black", cursor: "pointer" }}>
                    <Icon icon="menu" size="20" />
                </div>
            </div>
            <div className="scroll-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "white" }}>
                <div style={{ maxWidth: 700,
                                padding: 20 }}>
                <Flex
                    direction="column"
                    gap={{
                        row: 0,
                        column: 12,
                        gap: 15
                    }}
                >
                    <Text>
                        ShopTheSwatch was created by Tony Bertolo to address a problem his then
                        fiance (now wife) was having while trying to pick her bridesmaid dresses. You can contact
                        him via <Link color='inverse' to="mailto:shoptheswatch@popflame.com">shoptheswatch@popflame.com</Link>.
                    </Text>
                    <img src={colorfulDresses} style={{ width: "100%", borderRadius: 30 }} />
                    <Text>
                        She had exactly the right color in mind, but struggled to find a dress that matched,
                        spending countless hours scouring site to site looking for a dress that matched the picture
                        she found on Pinterest.
                    </Text>
                    <Text>
                        Tony, being a software engineer, set out to build a tool that could take the exact color she
                        was looking for and show her dresses from different retailers that either match or come close to the exact color using advanced color theory. Alas,
                        ShopTheSwatch.com was born.
                    </Text>
                </Flex>
                </div>
            </div>
        </div>

        {showNav && (
            <Layer zIndex={sheetZIndex}>
                <OverlayPanel size="sm" onDismiss={() => { setShowNav(false) }}>
                    <SideNavigation accessibilityLabel="Main example">
                        <SideNavigation.TopItem
                            href="/"
                            onClick={({ event }) => window.location.href = "/"}
                            label="Home"
                        />
                        <SideNavigation.TopItem
                            href="/about"
                            onClick={({ event }) => window.location.href = "/about"}
                            label="About"
                        />
                        <SideNavigation.TopItem
                            href="/terms"
                            onClick={({ event }) => window.location.href = "/terms"}
                            label="Terms of Use"
                        />
                        <SideNavigation.TopItem
                            href="/privacy"
                            onClick={({ event }) => window.location.href = "/privacy"}
                            label="Privacy Policy"
                        />
                    </SideNavigation>
                </OverlayPanel>
                </Layer>
            )}
        </>
    )
}

export default About;