import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import {
    Button,
    CompositeZIndex,
    FixedZIndex,
    Flex,
    IconButton,
    Heading,
    Layer,
    Modal,
    RadioGroup,
    Divider,
    Text,
    Box,
    OverlayPanel,
    SideNavigation,
    Icon
} from 'gestalt';
import { HexColorPicker } from "react-colorful";
import { Link, Redirect, Route, useHistory, useParams } from "react-router-dom";

import '@fontsource/public-sans';
import 'gestalt/dist/gestalt.css';

import { Masonry } from 'gestalt';
import Product from '../components/Product';

import mainLogo from '../images/shoptheswatch.svg';
import downtownStore from '../images/downtown-store.jpeg';
import colorfulDresses from '../images/colorful-dresses.jpeg';

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const Terms = (props) => {
    const [showNav, setShowNav] = useState(false);
    const HEADER_ZINDEX = new FixedZIndex(10);

    const sheetZIndex = new CompositeZIndex([HEADER_ZINDEX]);

    const navigation = useHistory();

    return (
        <>
            <div className="page-container">
                <div className="page-header" style={{ borderBottomColor: "rgba(0,0,0,0.1)", borderBottomWidth: 1 }}>
                    <div className="logo">
                        <img src={mainLogo} />
                    </div>
                    <div onClick={() => { setShowNav(!showNav) }} style={{ marginLeft: 20, color: "black", cursor: "pointer" }}>
                        <Icon icon="menu" size="20" />
                    </div>
                </div>
                <div className="scroll-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "white", color: "black" }}>
                    <div style={{ maxWidth: 700,
                                padding: 20 }}>
                        <Flex
                            direction="column"
                            gap={{
                                row: 0,
                                column: 12,
                                gap: 15
                            }}
                        >
                            <div className="css-1dbjc4n r-13awgt0 r-eqz5dr r-1pcd2l5"><h1>1. Terms of Service (End User License Agreement or EULA)</h1>
                                <p>By agreeing to and following these terms, ShopTheSwatch is allowing you access to and use of it's mobile application and web client.</p>
                                <p>By using the ShopTheSwatch app on any platform, or it's related online services at https://ShopTheSwatch.com (hereby in these terms further referred to as "ShopTheSwatch") in any way, You (as an individual or organization represented here by the person making use of ShopTheSwatch) must agree to the terms set forth in this agreement.</p>
                                
                                <h2>Scope</h2>
                                <p>This agreement is between you and ShopTheSwatch (Anthony Bertolo and ShopTheSwatch) and doesn't include or superceed any agreements between You and any other entity, including but not limited to Apple, Inc, Google, Microsoft or any other organization, person or entity.</p>
                                
                                <h2>Legal Compliance</h2>
                                <p>You are hereby representing that you have the legal authority to enter into this agreement and you (i) aren't located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) You aren't listed on any U.S. Government list of prohibited or restricted parties.</p>
                                
                                <h2>Maintenance And Support</h2>
                                <p>ShopTheSwatch is responsible for the maintenance and availability of the app and it's assoicated services. No other orgainzation is responsible for maintaining ShopTheSwatch or it's related products or services.</p>
                                
                                <h2>Warranty</h2>
                                <p>ShopTheSwatch makes no warranty, implied or otherwise of it's availability. ShopTheSwatch has no liability, implied or otherwise for any damages that may occur from the use of or availability of the application or it's related services.</p>
                                
                                <h2>Violation of Terms</h2>
                                <p>Violation of any of these terms can result in one of the following actions at the descretion of ShopTheSwatch or it's beneficiaries:</p>
                                <ul>
                                    <li><strong>Temporary Ban</strong>: This can be for hours, days, weeks, months or longer.</li>
                                    <li><strong>Permanent Ban</strong>: Your access will be gone forever.</li>
                                </ul>

                                <h1>2. No Automation</h1>
                                <p>Under no circumstances unless permission to do so is expressly granted by ShopTheSwatch are you allowed to use any kind of automated services or scripts to crawl or otherwise index items listed on ShopTheSwatch.</p>
                                
                                <h1>3. Liability</h1>
                                <p>ShopTheSwatch is a listing service and is in no way liable for any products found or purchased after finding them on ShopTheSwatch.com. Any and all issues are the liability of the party in which you purchased the items from. No warranty is provided, expressed or implied for the use of ShopTheSwatch.</p>
                                
                                <h2>Automated Access</h2>
                                <p>Unless covered in a separate agreement for a specific services, like a Public API provided by ShopTheSwatch, you aren't allowed to access ShopTheSwatch using any kind of automated service.</p>

                                <h1>7. Third Party Beneficiaries</h1>
                                <p>Apple, and Apple’s subsidiaries, are third party beneficiaries of the this agreement, and that, upon the Your acceptance of the terms and conditions of the EULA, Apple will have the right (and will be deemed to have accepted the right) to enforce the EULA against the End-User as a third party beneficiary thereof.</p>
                                
                                <h1>8. Claims</h1>
                                <p>Any claims related to or directed at ShopTheSwatch, it's mobile app or other related services should be directed at ShopTheSwatch via the contact information in this agreement. No other party is responsible for claims relating to the use of the ShopTheSwatch Social mobile application, it's services or any related product or services provided by Anthony Bertolo or ShopTheSwatch Network, Inc. Any claims, including but not limited to illegal activities, distribution or otherwise as it pertains to ShopTheSwatch, it's applications or it's services are the responsiblity of ShopTheSwatch and should be directed to the contact information below:</p>
                                <p>E-Mail: <em>claims@popflame.com</em></p>
                                
                                <h1>8. Contact</h1>
                                <p>You may contact ShopTheSwatch with general EULA inqueries via e-mail: <em>terms@popflame.com</em></p>
                                
                                <h2>Location</h2>
                                <p>ShopTheSwatch is operated by Anthony Bertolo located in Portalnd, Oregon of the United States of America.</p></div>
                        </Flex>
                    </div>
                </div>
            </div>

            {showNav && (
                <Layer zIndex={sheetZIndex}>
                    <OverlayPanel size="sm" onDismiss={() => { setShowNav(false) }}>
                        <SideNavigation accessibilityLabel="Main example">
                            <SideNavigation.TopItem
                                href="/"
                                onClick={({ event }) => window.location.href = "/"}
                                label="Home"
                            />
                            <SideNavigation.TopItem
                                href="/about"
                                onClick={({ event }) => window.location.href = "/about"}
                                label="About"
                            />
                            <SideNavigation.TopItem
                                href="/terms"
                                onClick={({ event }) => window.location.href = "/terms"}
                                label="Terms of Use"
                            />
                            <SideNavigation.TopItem
                                href="/privacy"
                                onClick={({ event }) => window.location.href = "/privacy"}
                                label="Privacy Policy"
                            />
                        </SideNavigation>
                    </OverlayPanel>
                </Layer>
            )}
        </>
    )
}

export default Terms;