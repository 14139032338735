import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import {
    Button,
    CompositeZIndex,
    FixedZIndex,
    Flex,
    IconButton,
    Heading,
    Layer,
    Modal,
    RadioGroup,
    Divider,
    Text,
    Box,
    OverlayPanel,
    Icon,
    SideNavigation,
    Dropdown
} from 'gestalt';
import { HexColorPicker } from "react-colorful";
import { Link, Redirect, Route, useHistory, useParams } from "react-router-dom";

import '@fontsource/public-sans';
import 'gestalt/dist/gestalt.css';

import { Masonry } from 'gestalt';
import Product from '../components/Product';

import mainLogo from '../images/shoptheswatch.svg';
import downtownStore from '../images/downtown-store.jpeg';
import colorfulDresses from '../images/colorful-dresses.jpeg';

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const Home = (props) => {
    const { color: urlColor } = useParams();
    const [products, setProducts] = useState([]);
    const [colWidth, setColWidth] = useState(null);
    const [gettingProducts, setGettingProducts] = useState(true);
    const [color, setColor] = useState(urlColor ? `#${urlColor}` : '#9ae3b3');
    const [showModal, setShowModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageColor, setImageColor] = useState('#ffffff');
    const [activeColor, setActiveColor] = useState(0);
    const [colorList, setColorList] = useState([color]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showFirstModal, setShowFirstModal] = useState(false);
    const [showAbout, setShowAbout] = useState(false);
    const [category, setCategory] = useState('dresses');
    const [showCategories, setShowCategories] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({ value: 'dresses', label: 'Dresses' });

    const HEADER_ZINDEX = new FixedZIndex(10);
    const zIndex = new CompositeZIndex([HEADER_ZINDEX]);

    const scrollRef = useRef();
    const scrollContainerRef = useRef();
    const imageUploadRef = useRef();
    const canvasRef = useRef();
    const categoryButtonRef = useRef();

    const history = useHistory();

    const [showNav, setShowNav] = useState(false);

    const sheetZIndex = new CompositeZIndex([HEADER_ZINDEX]);

    const navigation = useHistory();

    const checkFirstLoad = async () => {
        const hasFirstLoad = localStorage.getItem('firstLoad');

        if (!hasFirstLoad) {
            setShowFirstModal(true);
            localStorage.setItem('firstLoad', "true");
        }
    }

    useEffect(() => {
        //getProducts();
        checkFirstLoad();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            const currentWidth = scrollRef.current.style.width;
            scrollRef.current.style.width = "1px";
            scrollRef.current.style.width = currentWidth;
        })
    }, [products]);

    useEffect(() => {
        if (!gettingProducts || currentPage === 1) {
            setTimeout(() => {
                getProducts();
            }, 1000);
        }
    }, [currentPage]);

    const getProducts = async () => {
        setGettingProducts(true);
        const productResponse = await fetch(`https://api.shoptheswatch.com/products?color=${color.replace('#', '')}&page=${currentPage}`);

        const productObj = await productResponse.json();

        setProducts(currentPage === 1 ? productObj : [...products, ...productObj]);
        setGettingProducts(false);
    }

    const handleSwatchClick = (e) => {
        setShowModal(true);
    }

    const handleImagePicked = (file) => {
        if (!file) {
            return
        }
        var input = file.target;
        var reader = new FileReader();

        reader.onload = () => {
            var dataURL = reader.result;
            setImageUrl(dataURL);
            setShowImageModal(true);
        };

        reader.readAsDataURL(input.files[0]);
    }

    const getColorOfPress = (e) => {
        const img = document.getElementById("image-selected");
        let x = e.nativeEvent.offsetX;
        let y = e.nativeEvent.offsetY;

        //console.log(x, y, img.width, img.height);

        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);

        var pixelData = canvas.getContext('2d').getImageData(x, y, 1, 1).data;

        console.log(pixelData);

        const imageXYColor = rgbToHex(pixelData[0], pixelData[1], pixelData[2]);

        console.log(imageXYColor);
        setColor(imageXYColor);
    }

    const getColorOfMove = (e) => {
        e.nativeEvent.preventDefault = true;
        const img = document.getElementById("image-selected");
        var bcr = e.target.getBoundingClientRect();
        var x = e.targetTouches[0].clientX - bcr.x;
        var y = e.targetTouches[0].clientY - bcr.y;

        //console.log(x, y, img.width, img.height);

        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);

        var pixelData = canvas.getContext('2d').getImageData(x, y, 1, 1).data;

        const imageXYColor = rgbToHex(pixelData[0], pixelData[1], pixelData[2]);
        setColor(imageXYColor);
    }

    const startColorSelect = () => {
        // nothing yet
    }

    const handleColorUpdate = () => {
        console.log("Updating color");
        window.history.replaceState(null, "Shop The Swatch!", `/${color.replace('#', '')}`);
        history.push(`${color.replace('#', '')}`);

        setTimeout(() => {
            setCurrentPage(1);
        }, 100);

        setProducts([]);
    }

    const handleSelect = (event) => {
        setSelectedCategory(event.item);
        setShowCategories(false);
    }

    const maxCols = 6;
    const minCols = 2;
    const minColWidth = 300;

    let columnWidth = window.innerWidth / maxCols;
    // decide how big to make cols
    const numCols = Math.floor(window.innerWidth / minColWidth);
    if (numCols < minCols) {
        columnWidth = window.innerWidth / 2;
    } else {
        columnWidth = window.innerWidth / numCols;
    }

    columnWidth = columnWidth - 0;

    return (
        <div className="page-container" ref={scrollRef}>
            <div className="page-header">
                <div className="logo">
                    <img src={mainLogo} />
                </div>
                {/* <div className="about" color="shopping" onClick={() => { setShowAbout(true) }} style={{ marginRight: 20, color: "black", cursor: "pointer" }}>
                    About
                </div> */}
                {/* <Button iconEnd="arrow-down" onClick={() => { setShowCategories(!showCategories); }} ref={categoryButtonRef} size="lg" text={selectedCategory.label} /> */}
                <div className="swatch">
                    <div className="current-color" style={{ backgroundColor: color }} onClick={handleSwatchClick}></div>
                </div>
                <div onClick={() => { setShowNav(true) }} style={{ marginLeft: 20, color: "black", cursor: "pointer" }}>
                    <Icon icon="menu" size="20" />
                </div>
            </div>
            <div className="scroll-container" ref={scrollContainerRef}>
                {/* <div style={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "center", color: "black", gap: 40, marginBottom: 40 }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: 40, padding: 20, alignItems: "center" }}>
                        <img src={mainLogo} style={{ width: 500 }} />
                        <div style={{ fontWeight: 100, fontSize: 36 }}>start shopping by picking a color</div>
                        <div style={{ padding: 20, paddingLeft: 30, paddingRight: 30, fontWeight: "bold", fontSize: 28, color: "white", backgroundColor: "#e30020", cursor: "pointer", borderRadius: 100, textAlign: "center", display: "inline-block" }} onClick={() => { setShowFirstModal(false); setShowModal(true); }}>Pick a color!</div>
                    </div>
                </div> */}
                {gettingProducts && products.length === 0 && (
                    <div style={{ display: "flex", color: "black", flex: 1, width: "100%", height: "100%", alignItems: "center", justifyContent: "center", fontWeight: "bold" }}><span>Finding items....</span></div>
                )}
                {scrollContainerRef.current && (
                    <Masonry
                        items={products}
                        layout={"basicCentered"}
                        columnWidth={columnWidth}
                        gutterWidth={0}
                        minCols={2}
                        renderItem={(data, itemId, isMeasuring) => {
                            return (
                                <Product product={data.data} colWidth={colWidth} key={`product-${data.data.id}`} />
                            )
                        }}
                        loadItems={(data) => {
                            console.log("load items", data);
                            setCurrentPage(currentPage + 1);
                        }}
                        scrollContainer={scrollContainerRef.current}
                    />
                )}
            </div>
            { showCategories && (
                <Dropdown
                    zIndex={new CompositeZIndex([HEADER_ZINDEX])}
                    anchor={categoryButtonRef.current}
                    onDismiss={() => { setShowCategories(false); }}
                    >
                        <Dropdown.Section label="Women">
                            <Dropdown.Item
                                onSelect={handleSelect}
                                option={{ value: 'dresses', label: 'Dresses' }}
                                selected={selectedCategory} />
                        </Dropdown.Section>
                        <Dropdown.Section label="Men">
                            <Dropdown.Item
                                onSelect={handleSelect}
                                option={{ value: 'suits', label: 'Suits' }}
                                selected={selectedCategory} />
                        </Dropdown.Section>
                    </Dropdown>
            )}
            <input type="file" ref={imageUploadRef} onChange={handleImagePicked} accept='image/*' style={{ display: "none" }} />
            {showModal && (
                <Layer zIndex={zIndex}>
                    <Modal
                        accessibilityModalLabel="Choose a color to search for"
                        align="start"
                        heading={
                            <Flex justifyContent="between">
                                <Heading size="500" accessibilityLevel={1}>
                                    Choose a color
                                </Heading>
                                <IconButton
                                    accessibilityLabel="Dismiss modal"
                                    bgColor="white"
                                    icon="cancel"
                                    iconColor="darkGray"
                                    onClick={() => { setShowModal(false) }}
                                    size="sm"
                                />
                            </Flex>
                        }
                        onDismiss={() => {
                            setShowModal(!showModal);
                        }}
                        footer={
                            <Flex justifyContent="end" gap={2}>
                                <Button color="gray" onClick={() => { setShowModal(false) }} text="Cancel" />
                                <Button color="red" onClick={() => { setShowModal(false); handleColorUpdate(); }} text="Search!" />
                            </Flex>
                        }
                        size="sm"
                    >
                        <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 40 }}>
                            <Button color="red" size="lg" text="Choose Image" onClick={() => { imageUploadRef.current.click(); if (imageUrl) { setShowImageModal(true) } }} />
                            <span className="help">You can choose an image from your device and pick a color from that image, or select a color using the color picker below.</span>
                            <Divider />
                            <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 20 }}>
                                <HexColorPicker color={color} onChange={setColor} />
                            </div>
                            <div style={{ backgroundColor: color, width: "100%", height: 50, borderRadius: 10 }}>

                            </div>
                        </div>
                    </Modal>
                </Layer>
            )}
            {showImageModal && (
                <Layer zIndex={zIndex}>
                    <Modal
                        accessibilityModalLabel="Choose a color to search for"
                        align="start"
                        heading={
                            <Flex justifyContent="between">
                                <Heading size="500" accessibilityLevel={1}>
                                    Choose a color
                                </Heading>
                                <IconButton
                                    accessibilityLabel="Dismiss modal"
                                    bgColor="white"
                                    icon="cancel"
                                    iconColor="darkGray"
                                    onClick={() => { setShowImageModal(false); }}
                                    size="sm"
                                />
                            </Flex>
                        }
                        onDismiss={() => {
                            setShowImageModal(false);
                        }}
                        footer={
                            <Flex justifyContent="end" gap={2}>
                                <Button color="gray" onClick={() => { setShowImageModal(false) }} text="Cancel" />
                                <Button color="red" onClick={() => { setShowModal(false); setShowImageModal(false); handleColorUpdate(); }} text="Search!" />
                            </Flex>
                        }
                        size="sm"
                    >
                        <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 40, userSelect: "none" }}>
                            <img src={imageUrl} style={{ width: "100%", maxHeight: "40vh", backgroundPosition: "center", objectFit: "contain" }} onDragStart={() => false} id="image-selected" onMouseUp={getColorOfPress} onMouseDown={startColorSelect} onTouchMove={getColorOfMove} />

                            <div style={{ backgroundColor: color, width: "100%", height: 50, borderRadius: 10 }}>

                            </div>
                        </div>
                    </Modal>
                </Layer>
            )}
            {showFirstModal && (
                <Layer zIndex={zIndex}>
                    <Modal
                        accessibilityModalLabel="Choose a color to search for"
                        align="start"
                        heading={
                            <Flex justifyContent="between">
                                <Heading size="500" accessibilityLevel={1}>
                                    Welcome to ShopTheSwatch!
                                </Heading>
                                <IconButton
                                    accessibilityLabel="Dismiss modal"
                                    bgColor="white"
                                    icon="cancel"
                                    iconColor="darkGray"
                                    onClick={() => { setShowFirstModal(false); }}
                                    size="sm"
                                />
                            </Flex>
                        }
                        onDismiss={() => {
                            setShowFirstModal(false);
                        }}
                        footer={
                            <Flex justifyContent="end" gap={2}>
                                <Button color="gray" onClick={() => { setShowFirstModal(false) }} text="Cancel" />
                                <Button color="red" onClick={() => { setShowFirstModal(false); setShowModal(true); }} text="Pick a Color!" />
                            </Flex>
                        }
                        size="sm"
                    >
                        <img src={downtownStore} style={{ width: "100%", borderRadius: 30, marginBottom: 20 }} />
                        <Box>
                            <Text style={{ marginBottom: 20 }}>ShopTheSwatch lets you find dresses from popular retailers by picking the color you want and handling the rest.</Text>
                            <br />
                            <Text>If you've ever had a hard time finding dresses in the color you're looking for, having to go site to site, your troubles are over. It's like magic. Pick a color from a color picker or use a photo and watch the magic happen!</Text>
                        </Box>
                    </Modal>
                </Layer>
            )}
            {showAbout && (
                <Layer zIndex={zIndex}>
                    <OverlayPanel
                        accessibilityDismissButtonLabel="Close about panel"
                        accessibilityLabel="About ShopTheSwatch"
                        heading="About ShopTheSwatch"
                        onDismiss={() => setShowAbout(false)}
                        size="sm"
                    >
                        <Flex
                            direction="column"
                            gap={{
                                row: 0,
                                column: 12,
                                gap: 15
                            }}
                        >
                            <Text>
                                ShopTheSwatch was created by Tony Bertolo to address a problem his then
                                fiance (now wife) was having while trying to pick her bridesmaid dresses. You can contact
                                him via <Link color='inverse' to="mailto:shoptheswatch@popflame.com">shoptheswatch@popflame.com</Link>.
                            </Text>
                            <img src={colorfulDresses} style={{ width: "100%", borderRadius: 30 }} />
                            <Text>
                                She had exactly the right color in mind, but struggled to find a dress that matched,
                                spending countless hours scouring site to site looking for a dress that matched the picture
                                she found on Pinterest.
                            </Text>
                            <Text>
                                Tony, being a software engineer, set out to build a tool that could take the exact color she
                                was looking for and show her dresses from different retailers that either match or come close to the exact color using advanced color theory. Alas,
                                ShopTheSwatch.com was born.
                            </Text>
                        </Flex>
                    </OverlayPanel>
                </Layer>
            )}
            {showNav && (
                <Layer zIndex={sheetZIndex}>
                    <OverlayPanel size="sm" onDismiss={() => { setShowNav(false) }}>
                        <SideNavigation accessibilityLabel="Main example">
                            <SideNavigation.TopItem
                                href="/"
                                onClick={({ event }) => window.location.href = "/"}
                                label="Home"
                            />
                            <SideNavigation.TopItem
                                href="/about"
                                onClick={({ event }) => window.location.href = "/about"}
                                label="About"
                            />
                            <SideNavigation.TopItem
                                href="/terms"
                                onClick={({ event }) => window.location.href = "/terms"}
                                label="Terms of Use"
                            />
                            <SideNavigation.TopItem
                                href="/privacy"
                                onClick={({ event }) => window.location.href = "/privacy"}
                                label="Privacy Policy"
                            />
                        </SideNavigation>
                    </OverlayPanel>
                </Layer>
            )}
        </div>
    )
}

export default Home;